export const JobPositionStatus = {
    REQUIREMENT_EXPIRED: "Expired",
    REQUIREMENT_COMPLETED: "Completed",
    REQUIREMENT_PASSED: "Passed",
    REQUIREMENT_FAILED: "Failed",
    REQUIREMENT_NOT_QUALIFIED: "Not Qualified",
    REQUIREMENT_QUALIFIED: "Qualified",
    TASK_SUSPENDED_STATUS: "suspend",
    TASK_NOT_SUSPENDED_STATUS: "not suspend",
    TASK_STATUS_SUSPENDED: "Suspended"
}

export const redStatusValues = ["Expired", "Not Qualified", "Failed"]
export const greenStatusValues = ["Completed", "Passed", "Qualified"]


export function getColorBasedOnTaskStatus(taskStatus, taskSuspendedStatus) {
    var color = "";
    if (taskStatus === JobPositionStatus.REQUIREMENT_QUALIFIED && taskSuspendedStatus === JobPositionStatus.TASK_NOT_SUSPENDED_STATUS) {
        color = "green";
    } else if (taskStatus === JobPositionStatus.REQUIREMENT_NOT_QUALIFIED) {
        color = "red";
    } else if (taskSuspendedStatus === JobPositionStatus.TASK_SUSPENDED_STATUS && taskStatus !== JobPositionStatus.REQUIREMENT_NOT_QUALIFIED) {
        color = "orange";
    } else if (taskStatus === JobPositionStatus.TASK_STATUS_SUSPENDED) {
        color = "orange";
    }
    return color;
}

export function getColorBasedOnJobStatus(status) {
    if (status === JobPositionStatus.REQUIREMENT_QUALIFIED) {
        return "green";
    } else if (status === JobPositionStatus.REQUIREMENT_NOT_QUALIFIED) {
        return "red";
    } else if (status === JobPositionStatus.TASK_STATUS_SUSPENDED) {
        return "orange";
    }
}

export function getJobStatusAsPerTaskStatus(list) {
    var status = "";
    var suspendedCntr = 0
    var notQualifiedCntr = 0
    var qualifiedCntrCntr = 0
    if(list.filter((task)=>task.taskStatus === JobPositionStatus.REQUIREMENT_QUALIFIED).length === list.length){ //Green
        status = JobPositionStatus.REQUIREMENT_QUALIFIED;
    }else if (list.filter((task)=>task.taskStatus === JobPositionStatus.REQUIREMENT_NOT_QUALIFIED).length === list.length){ // Red
        status = JobPositionStatus.REQUIREMENT_NOT_QUALIFIED;
    }else if (list.filter((task)=>task.taskStatus === JobPositionStatus.TASK_STATUS_SUSPENDED).length === list.length){ //Orange
        status = JobPositionStatus.TASK_STATUS_SUSPENDED;
    }else if (list.filter((task)=>{

        if(task.taskStatus === JobPositionStatus.TASK_STATUS_SUSPENDED){
            suspendedCntr++
          }else if(task.taskStatus === JobPositionStatus.REQUIREMENT_NOT_QUALIFIED){
            notQualifiedCntr++
          }else if(task.taskStatus === JobPositionStatus.REQUIREMENT_QUALIFIED){
            qualifiedCntrCntr++
          }
      
          return (suspendedCntr > 0 && notQualifiedCntr > 0 && qualifiedCntrCntr > 0)
    }).length > 0){ // Checking if any task is qualified and the whole job is retured as qualified
        status = JobPositionStatus.REQUIREMENT_QUALIFIED;
    }else if(list.filter((task)=>{

        if(task.taskStatus === JobPositionStatus.TASK_STATUS_SUSPENDED){
            suspendedCntr++
        }else if(task.taskStatus === JobPositionStatus.REQUIREMENT_QUALIFIED){
            qualifiedCntrCntr++
        }
      
        return (suspendedCntr > 0 && qualifiedCntrCntr > 0)
    }).length > 0){
        status = JobPositionStatus.REQUIREMENT_QUALIFIED;
    }else if(list.filter((task)=>{

        if(task.taskStatus === JobPositionStatus.REQUIREMENT_NOT_QUALIFIED){
            notQualifiedCntr++
        }else if(task.taskStatus === JobPositionStatus.REQUIREMENT_QUALIFIED){
            qualifiedCntrCntr++
        }
      
        return (notQualifiedCntr > 0 && qualifiedCntrCntr > 0)
    }).length > 0){
        status = JobPositionStatus.REQUIREMENT_QUALIFIED;
    }else if (list.filter((task)=>((task.taskStatus === JobPositionStatus.TASK_STATUS_SUSPENDED || task.taskStatus === JobPositionStatus.REQUIREMENT_NOT_QUALIFIED) && task.taskStatus !== JobPositionStatus.REQUIREMENT_QUALIFIED)).length > 0){
        status = JobPositionStatus.TASK_STATUS_SUSPENDED; // Checking if any task is suspended or non-qualified and if not containing qualified tasks , the job is returned as Suspended
    }
    return status;
}