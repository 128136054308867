import React from "react";
import './header.css';
import MEA from "../../image/mealogo.svg";
import Bitmap from "../../image/bitmap.svg";
import Stack from 'react-bootstrap/Stack';
import { Container } from 'react-bootstrap'
import { getPhoneNumberBeforeExtensionNumber, getExtensionNumber, isEmpty, isNull, isUndefined } from "../../utils/utils";

function Header({ userDetails }) {
    const onErrorImg = (event) => {
        event.target.src = Bitmap
        event.onerror = null
    }
    const constructPhoneNumberByExtension = (phoneNumber) => {
        var phone = "";
        if (!isNull(phoneNumber) && !isEmpty(phoneNumber) && phoneNumber !== undefined) {
            if (phoneNumber.trim() !== "x") {
                var constructedExtensionNum = !isNull(getExtensionNumber(phoneNumber.trim())) && !isEmpty(getExtensionNumber(phoneNumber.trim())) ? "(" + getExtensionNumber(phoneNumber.trim()) + ")" : "";
                var constructedPhNum = !isNull(getPhoneNumberBeforeExtensionNumber(phoneNumber.trim())) && !isEmpty(getPhoneNumberBeforeExtensionNumber(phoneNumber.trim())) ? getPhoneNumberBeforeExtensionNumber(phoneNumber.trim()) : "";
                phone = constructedExtensionNum + constructedPhNum
            }
        }
        return phone;
    }

    const getLoginName = (firstName, lastName) => {
        var fName = "", lName = "";
        if (!isUndefined(firstName) && !isNull(firstName) && !isEmpty(firstName) || !isUndefined(lastName) && !isNull(lastName) && !isEmpty(lastName)) {
            fName = firstName;
            lName = lastName
        }
        return fName + " " + lName;
    }
    return (
        <div className="header-container">
            <Stack gap={2} className="col-md-5 mx-auto">
                <div className="header-profile-logo">
                    <img className="logo" src={MEA} alt="MEA" />
                </div>
                <div className="header-profile-picture">
                    <img className="bitmap-image" src={(userDetails.profileUrl != null && userDetails.profileUrl != "") ? userDetails.profileUrl : Bitmap} alt="ProfilePhoto" onError={onErrorImg} />
                </div>
                <div className="header-profile-name">
                    <label className="header-profile-name-title">{getLoginName(userDetails.firstName, userDetails.lastName)}</label>
                </div>
                <div>
                    <span className="header-profile-login">Login ID : </span>
                    <span className="header-profile-login-name">{userDetails.loginName}</span>
                </div>
                <div>
                    <span className="header-profile-telephone">Telephone : </span>
                    <span className="header-profile-telephone-title">{constructPhoneNumberByExtension(userDetails.phone)}</span>
                </div>
                {userDetails.statusName === 'Suspended' ?
                    <div className="header-profile-status-div">
                        <span className="header-profile-status">User Status : </span>
                        <span className="header-profile-status-title">{userDetails.statusName}</span>
                    </div>
                    :
                    <div></div>
                }


            </Stack>
        </div>
    );
}

export default Header;