import { Row, Col, Accordion } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
function AccordionContent({ data, render , initialHeaderName}) {
    return (
        <div className="job-table-section">
            <div className="main-table">
                <Row className="main-table-header">
                    <Col xs={4} md={6}><Button className="main-table-header-title-initial" size="sm">{initialHeaderName}</Button></Col>
                    <Col xs={4} md={3}><Button className="main-table-header-title" size="sm">Completed</Button></Col>
                    <Col xs={4} md={3}><Button className="main-table-header-title" size="sm">Expired</Button></Col>
                </Row>
                <Accordion defaultActiveKey={0} className="parent-accordion">
                {data.map(render)}
                </Accordion>
            </div>
        </div>
    )
}

export default AccordionContent;