import { Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { isUndefined } from "../../utils/utils";
function Requirements({ data, render }) {
    return (
        <div className="job-table-section">
            <div className="main-table">
                <Row className="main-table-header">
                    <Col xs={8} md={10}><Button className="main-table-header-title" size="sm">Requirement Name</Button></Col>
                    <Col xs={4} md={2}><Button className="main-table-header-title" size="sm">Completed</Button></Col>
                </Row>
                {data.map(render)}
            </div>
        </div>
    )
}

export default Requirements;