import React from 'react';
import ReactDOM from 'react-dom';
import Scanner from './routes/scannercode.component';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
 ReactDOM.render(
  <React.Fragment>
     <Scanner />
  </React.Fragment>,
  document.getElementById('root')
);

reportWebVitals();
