import * as moment from 'moment';
export function isUndefined(value) {
    return value === undefined
}

export function isNull(value) {
    return value === null || value === "null"
}

export function isEmpty(value) {
    return value === ""
}

export function isArrayEmpty(value){
    return value.length === 0
}

export function getDaysFromCertainDays(days) {
    var d = new Date();
    d.setDate(d.getDate() - days);
    d = Date.UTC(d.getFullYear(),d.getMonth(),d.getDate())
    return d;
}

export function getCurrentDateStamp() {
    var d = new Date();
    d = Date.UTC(d.getFullYear(),d.getMonth(),d.getDate())
    return d;
}

export function getExtensionNumber(str) {
    return str.split('x')[1] !== undefined ? str.split('x')[1] : "";
}

export function getPhoneNumberBeforeExtensionNumber(str) {
    return str.split('x')[0] !== undefined ? str.split('x')[0] : "";
}

export function getBoolean(str) {
    if (str === "true") {
        return true;
    } else if (str === "false") {
        return false;
    }
}
export function getDateFormat(inputDate, format) {
    //parse the input date
   
    const mdate= moment(inputDate)
     const day = mdate.format('DD');
    const month = mdate.format('MM');
    const year = mdate.format('YYYY');

    //replace the month
    format = format.replace("MM", month.toString().padStart(2,"0"));        

    //replace the year
    if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2,2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2,"0"));

    return format;
}