import { Row , Col} from 'react-bootstrap';
import './radioTab.css';

function RadioTab({ onTabChange, selectedTab, data }) {
    return (
        <div className="mb-3 radio-tab-container">
            {
                data.map((tab, index) => {
                    return (
                        <label key={index} className="radio-tab-task-container">
                        <input type="radio" name="radio-list" className="cursor-pointer radio-tab-task-input"
                            value={tab.id} onChange={(e) => onTabChange(e.target.value)} checked={parseInt(selectedTab) === tab.id}
                        /> {tab.name}
                    </label>
                    )
                })
            }
        </div>
    );
}
export default RadioTab;