import React, { useState } from "react";
import { Row } from "react-bootstrap";
import './taskList.css'
import dropdown from "../../image/chevrondown.svg"
import { isNull } from "../../utils/utils";

function TaskList({ taskList, selectedOption, onOptionClicked, isOpen }) {
    const [open, setOpen] = useState(isOpen);

    const onTaskOptionClicked = (task) => {
     
        onOptionClicked(task);
        setOpen(!open)
    }

    return (
        <div className="task-list-container">
            <Row className="task-list-select-row">
                <div className="task-list-title">SELECT A TASK LIST </div>
                <div >
                    <div className="task-list-select" onClick={() => setOpen(!open)}>
                        <div className="task-list-select-item" style={{ 'width': '100%' }}>
                            {selectedOption !== null ? selectedOption.taskListName : "Select a task list"}
                        </div>
                        <div className="task-list-select-item">
                            <img src={dropdown} id="image-down" alt="Dropdown button" className="down-list" />
                        </div>
                    </div>
                    {open && taskList.map((task, index) => {
                        return (
                           
                          !isNull(selectedOption) ? selectedOption.taskListName !== task.taskListName &&
                            <div id={task.taskListId} className="task-list-select-option" key={index} onClick={() => onTaskOptionClicked(task)}>
                                
                                {task.taskListName}
                            </div> : 
                            <div id={task.taskListId} className="task-list-select-option" key={index} onClick={() => onTaskOptionClicked(task)}>
                            {task.taskListName}
                        </div>
                        )
                    })}
                </div>
            </Row>
        </div>
    );
}

export default TaskList;