import React, { useState } from "react";
import { Row } from "react-bootstrap";
import '../TaskList/taskList.css'
import dropdown from "../../image/chevrondown.svg"
import { isArrayEmpty, isNull, isUndefined } from "../../utils/utils";

function JobPositionList({ jobPositionList, selectedOption, onOptionClicked, isOpen }) {
    const [open, setOpen] = useState(isOpen);

    const onJobPositionOptionClicked = (task) => {
        onOptionClicked(task);
        setOpen(!open)
    }


    return (
        <div className="task-list-container">
            <Row className="task-list-select-row">
                <div className="task-list-title">SELECT A JOB POSITION</div>
                <div >
                    <div className="task-list-select" onClick={() => setOpen(!open)}>
                        {/* <div className="task-list-select-item" style={{ 'width': '100%' }}>
                            {renderDropdownPrompt(selectedOption)}
                        </div> */}
                         <div className="task-list-select-item" style={{ 'width': '100%' }}>
                            {selectedOption !== null ? selectedOption.positionTitle : "Select a job position"}
                        </div>
                        <div className="task-list-select-item">
                            <img src={dropdown} id="image-down" alt="Dropdown button" className="down-list" />
                        </div>
                    </div>
                    {open && jobPositionList.map((jobPosition, jobPositionIndex) => {
                      
                        return (
                            !isNull(selectedOption) ? selectedOption.positionTitle !==jobPosition.positionTitle &&
                            <div id={jobPosition.jobPositionId} className="task-list-select-option" key={jobPositionIndex}
                                onClick={() => onJobPositionOptionClicked(jobPosition)}>
                                {jobPosition.positionTitle}
                            </div> : 
                            <div id={jobPosition.jobPositionId} className="task-list-select-option" key={jobPositionIndex}
                            onClick={() => onJobPositionOptionClicked(jobPosition)}>
                            {jobPosition.positionTitle}
                        </div> 
                        )
                    })}
                </div>
            </Row>
        </div>
    );
}

export default JobPositionList;