import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "../components/main.js";

function Scanner() {
	return (
		<div className="Scanner">
			<Router>
				<Switch>
					<Route exact path="/" component={Main} />
				</Switch>
			</Router>
		</div>
	);
}

export default Scanner;
